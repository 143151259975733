const parentTarget = document.getElementById('filterGallery');

const hoverImages = () => {
  const targetImages = [].slice.call(document.querySelectorAll('.hover-image-container'));

  if (targetImages.length) {
    targetImages.forEach(targetImage => {
      targetImage.parentNode.addEventListener('mouseenter', event => {
        const imageContainer = event.target;
        const initialImage = imageContainer.querySelector('.initial-image');
        const hoverImage = imageContainer.querySelector('.hover-image');

        initialImage.classList.remove('show');
        initialImage.classList.add('hide');

        hoverImage.classList.remove('hide');
        hoverImage.classList.add('show');
      });

      targetImage.parentNode.addEventListener('mouseleave', event => {
        const imageContainer = event.target;
        const initialImage = imageContainer.querySelector('.initial-image');
        const hoverImage = imageContainer.querySelector('.hover-image');

        initialImage.classList.remove('hide');
        initialImage.classList.add('show');

        hoverImage.classList.remove('show');
        hoverImage.classList.add('hide');
      });
    });
  }
};

const parentHover = () => {
  parentTarget.addEventListener('mouseenter', hoverImages);
  parentTarget.removeEventListener('mouseleave', hoverImages);
};

if (parentTarget) {
  parentHover();
}
